<template>
  <div>
    <b-modal
      id="modal-edit-location"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('Edit Location')"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          id="input-group-1"
          :label="`${$t('Location Name')}:`"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="form.locationName"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          :label="`${$t('Select location type')}:`"
          label-for="input-1"
        >
          <vue-select
            v-model="form.locationType"
            style="min-width: 270px;"
            :options="getLocationTypes"
            :reduce="(e) => e.value"
            :placeholder="$t('SelectLocation')"
            label="label"
          />
        </b-form-group>
        <b-form-group
          label="Select store"
          label-for="input-1"
        >
          <vue-select
            v-model="form.storeName"
            :options="filteredStores"
            label="storeName"
            :clearable="false"
            placeholder="Select Store"
            style="width:100%;margin-right:10px;"
          />
          <p
            v-if="form.storeName.storeId == null || form.storeName.storeId === '' || form.storeName.storeId === '00000000-0000-0000-0000-000000000000'"
            style="color:red;"
          >
            {{ $t('StoreRequired') }}
          </p>
        </b-form-group>
        <b-form-group
          id="input-group-4"
          :label="`${$t('Notes')}:`"
          label-for="input-4"
        >
          <b-form-textarea
            id="input-4"
            v-model="form.notes"
            aria-describedby="input-1-live-feedback"
            class="form-control"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t('Must_be_at_least_3_characters') }}</b-form-invalid-feedback>
        </b-form-group>
      </b-form>
      <div class="buttonsEverywhere">
        <button
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="onSubmitLocation"
        >
          {{ $t('Submit') }}
        </button>
        <b-button
          type="reset"
          class="buttonCancel"
          style="margin-right: 15px; ; background: white; color: black; border-color: white;font-weight: 400;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import {
  required, minLength,
} from 'vuelidate/lib/validators';
// import maxLength from 'vuelidate/lib/validators/maxLength';

export default {
  mixins: [validationMixin],
  // eslint-disable-next-line vue/require-prop-types
  /* eslint-disable */
  props: ['editSupp'],
  data() {
    return {
      isPhoneNumberValid: null,
      form: {
        locationId: '',
        locationName: '',
        locationType: '',
        storeName:
          {
            storeName: "No filiale",
            storeId: null,
          },
        notes: '',
      },
      getLocationTypes: [
        { value: 'WarehouseForClient', label: this.$t('WarehouseForClient') },
        { value: 'WarehouseForProductInStock', label: this.$t('WarehouseForProductInStock') },
        { value: 'WarehouseForMaterialVariants', label: this.$t('WarehouseForMaterialVariants') },
        { value: 'Showroom', label: this.$t('Showroom') },
        { value: 'Shop', label: this.$t('Shop') },
        { value: 'MainWarehouse', label: this.$t('MainWarehouse') },
      ],
    }
  },
  validations: {
    form: {
      locationName: {
        required,
        minLength: minLength(3),
        // alpha,
      },
      locationType: {
        // required,
        minLength: minLength(3),
        // alpha,
      },
      notes: {
        required,
        minLength: minLength(1),
      },
    },
  },
  computed: {
    ...mapGetters([
      'getSuppliers',
      'getCurrentPageForSuppliers',
      'getTotalItemsForSuppliers',
      'getLoggedInUser',
      'getFilteredSuppliersBySN',
      'getFilteredSuppliersByCN',
      'getLocation',
      // 'getLocationTypes',
      'getLocationsByType',
      'getAllStoresDropdown',
    ]),
    filteredStores() {
      const filteredStores = this.getAllStoresDropdown.filter((store) => { return store.storeName !== 'all' && store.storeName !== 'unassigned' });

      const allOption = { storeId: null, storeName: 'All', storeGLN: null };
      return [allOption, ...filteredStores];
    },
  },
  watch: {
    editSupp(value) {
      this.form.items = value.items
      this.form.locationId = value.locationId
      this.form.locationName = value.locationName
      this.form.storeName = {
        storeId: value.storeId,
        storeName: value.storeName || 'No filiale',
      }
      this.form.locationType = value.locationType
      this.form.notes = value.notes

      console.log('storeName and storeId', this.form.storeName, this.form.storeId);
      // const selectedStore = this.filteredStores.find(store => store.storeId === value.storeId)
      //
      // if (selectedStore) {
      //   this.form.storeId = selectedStore.storeId;
      //   this.form.storeName = selectedStore.storeName;
      // } else {
      //   this.form.storeId = null;
      //   this.form.storeName = '';
      // }
    },
  },
  methods: {

    getFormattedNumber(n) {
      if (n.isValid == true) {
        this.isPhoneNumberValid = true;
        this.form.phone = n.nationalNumber;
        this.form.countryCode = n.countryCode;
      } else {
        this.form.countryCode = '';
        this.isPhoneNumberValid = false;
        return;
      }
      console.log();
    },
    onSubmitLocation() {
      if (this.form.storeName.storeId == null || this.form.storeName.storeId === '' || this.form.storeName.storeId === '00000000-0000-0000-0000-000000000000') {
        return;
      }
      const object = {
        ...this.form,
        storeName: this.form.storeName.storeName,
        storeId: this.form.storeName.storeId,
      }
      this.$emit('edit', object)
      this.$refs.modal.hide()
      this.onReset()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.onReset()
    },
    onReset() {
      this.form.items = this.editSupp.items
      this.form.locationId = this.editSupp.locationId
      this.form.locationName = this.editSupp.locationName
      this.form.storeName = this.editSupp.storeName
      this.form.locationType = this.editSupp.locationType
      this.form.notes = this.editSupp.notes
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-select {
  font-size: 13px; /* Adjust the font size to your preference */
  height: 36px; /* Adjust the height to your preference */
  padding: 6px 12px; /* Adjust the padding to your preference */
}
form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.flex-form {
  display: flex;
  flex-direction: column;
}
</style>

<style>
.blinori {
  background-color: #242F6E;
  color: white;
}

.blinori button {
  background-color: white;
  border-radius: 10px;
}

.leo {
  background-color: white;
  border-radius: 10px;
}

.form.control {
  width: 100%;
}
</style>
