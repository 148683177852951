<template>
  <div>
    <b-modal
      id="modal-new-location"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('Add Location')"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          id="input-group-1"
          :label="`${$t('CompanyName')}:`"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="form.locationName"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          :label="`${$t('Select location type')}:`"
          label-for="input-1"
        >
          <vue-select
            id="input-group-2"
            v-model="form.locationType"
            style="min-width: 270px;"
            :options="getLocationTypes"
            :reduce="(e) => e.value"
            :placeholder="$t('SelectLocation')"
            label="label"
          />
        </b-form-group>
        <b-form-group
          label="Select store"
          label-for="input-1"
        >
          <vue-select
            v-model="form.storeId"
            :options="filteredStores"
            label="storeName"
            :reduce="(e) => e.storeId"
            :clearable="true"
            placeholder="Select Store"
            style="width:100%;margin-right:10px;"
          />
        </b-form-group>
        <b-form-invalid-feedback id="input-1-live-feedback">
          {{ $t('Must_be_at_least_3_characters') }}
        </b-form-invalid-feedback>
        <b-form-group
          id="input-group-4"
          :label="`${$t('Notes')}:`"
          label-for="input-4"
        >
          <b-form-textarea
            id="input-4"
            v-model="form.notes"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t('Must_be_at_least_3_characters') }}</b-form-invalid-feedback>
        </b-form-group>

      </b-form>

      <div class="buttonsEverywhere">
        <button
          type="button"
          variant="none"
          class="buttonSubmit"

          @click="onSubmitLocation"
        >
          {{ $t('Submit') }}
        </button>
        <b-button
          type="reset"
          class="buttonCancel"
          style="margin-right: 15px; ; background: white; color: black; border-color: white;font-weight: 400;"

          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>

    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { mapGetters } from 'vuex';
import {
  required, minLength,
} from 'vuelidate/lib/validators';
// import maxLength from 'vuelidate/lib/validators/maxLength';

export default {
  mixins: [validationMixin],

  data() {
    return {
      isPhoneNumberValid: null,
      form: {
        locationName: '',
        locationType: '',
        notes: '',
        // doorNumber: '',
        // contactTitle: '',
        // city: '',
        // postalCode: '',
        // country: '',
        // countryCode: '',
        // phone: '',
        // email: '',
        // webSite: '',
      },
      getLocationTypes: [
        { value: 'WarehouseForClient', label: this.$t('WarehouseForClient') },
        { value: 'WarehouseForProductInStock', label: this.$t('WarehouseForProductInStock') },
        { value: 'WarehouseForMaterialVariants', label: this.$t('WarehouseForMaterialVariants') },
        { value: 'Showroom', label: this.$t('Showroom') },
        { value: 'Shop', label: this.$t('Shop') },
        { value: 'MainWarehouse', label: this.$t('MainWarehouse') },
      ],
    }
  },

  validations: {
    form: {
      locationName: {
        required,
        minLength: minLength(3),
        // alpha,
      },
      locationType: {
        // required,
        minLength: minLength(3),
        // alpha,
      },
      // doorNumber: {
      //   // required,
      //   minLength: minValue(1),
      //   numeric,
      // },
      // contactTitle: {
      //   // required,
      //   minLength: minLength(2),
      //   // alpha,
      // },
      // city: {
      //   required,
      //   minLength: minLength(3),
      //   // alpha,
      // },
      // postalCode: {
      //   required,
      //   minLength: minLength(3),
      //   alphaNum,
      // },
      // country: {
      //   required,
      //   minLength: minLength(3),
      //   // alpha,
      // },
      // // phone: {
      // //   required,
      // //   minLength: minLength(9),
      // //   numeric,
      // // },
      // email: {
      //   // required,
      //   email,
      // },
      // webSite: {
      //   // required,
      //   minLength: minLength(3),
      //   // alphaNum,
      // },
    },
  },
  computed: {
    ...mapGetters([
      'getSuppliers',
      'getCurrentPageForSuppliers',
      'getTotalItemsForSuppliers',
      'getLoggedInUser',
      'getFilteredSuppliersBySN',
      'getFilteredSuppliersByCN',
      'getLocation',
      // 'getLocationTypes',
      'getLocationsByType',
      'getAllStoresDropdown',
    ]),
    // formattedPhoneNumber() {
    //   const countryCode = this.user.countryCode;
    //   const callingCode = this.countryCallingCode[countryCode];
    //   const phoneNumber = this.user.phoneNumber;
    //   return `+${callingCode} ${phoneNumber}`;
    // },
    filteredStores() {
      const filteredStores = this.getAllStoresDropdown.filter((store) => { return store.storeName !== 'all' && store.storeName !== 'unassigned' });

      const allOption = { storeId: null, storeName: 'All', storeGLN: null };
      // const unassigned = { storeId: '00000000-0000-0000-0000-000000000000', storeName: 'Unassigned', storeGLN: null };
      return [allOption, ...filteredStores];
    },
  },
  methods: {
    // validateState(name) {
    //   const { $dirty, $error } = this.$v.form[name];
    //   return $dirty ? !$error : null;
    // },
    getFormattedNumber(n) {
      if (n.isValid == true) {
        this.isPhoneNumberValid = true;
        this.form.phone = n.nationalNumber;
        this.form.countryCode = n.countryCode;
      } else {
        this.form.countryCode = '';
        this.isPhoneNumberValid = false;
        return;
      }
      console.log();
    },
    onSubmitLocation() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      // Add storeId to the form object if it's selected
      if (this.form.storeId && this.form.storeId !== null) {
        this.$emit('location', {
          ...this.form,
          storeId: this.form.storeId, // Pass storeId to the emitted event
        });
      } else {
        // If no store is selected, just send the location data without storeId
        this.$emit('location', this.form);
      }
      this.$refs.modal.hide()
      this.onReset()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.onReset()
    },
    onReset() {
      // Reset our form values
      this.form.locationName = ''
      this.form.locationType = ''
      this.form.notes = ''
      // this.form.contactTitle = ''
      // this.form.city = ''
      // this.form.doorNumber = ''
      // this.form.postalCode = ''
      // this.form.country = ''
      // this.form.phone = ''
      // this.form.email = ''
      // this.form.webSite = ''
      // this.form.countryCode = '';
      // Trick to reset/clear native browser form validation state
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-select {
  font-size: 13px; /* Adjust the font size to your preference */
  height: 36px; /* Adjust the height to your preference */
  padding: 6px 12px; /* Adjust the padding to your preference */
}
form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}
.form2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.flex-form {
  display: flex;
  flex-direction: column;
}
</style>

<style>
.blinori {
  background-color: #242F6E;
  color: white;
}

.blinori button {
  background-color: white;
  border-radius: 10px;
}

.leo {
  background-color: white;
  border-radius: 10px;
}

.form.control {
  width: 100%;
}
</style>
