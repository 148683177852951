<template>
  <div
    style="margin-top: 0px;"
    @click="removeLists()"
  >
    <div>
      <div
        class="table__header "
        style="margin-bottom: 15px !important; justify-content: flex-start;"
      >
        <div class="table_top">
          <button
            v-b-modal.modal-new-location
            class="button"
          >
            {{ $t('Add Location') }}
          </button>
          <StoresDropdown
            :filiale="store"
            @updateFiliale="updateFiliale"
          />
<!--          <vue-select-->
<!--            v-model="storesId"-->
<!--            :options="filteredStores"-->
<!--            label="storeName"-->
<!--            :reduce="(e) => e.storeId"-->
<!--            :clearable="false"-->
<!--            placeholder="Select Store"-->
<!--            style="width:12%;margin-right:10px;"-->
<!--          />-->
        </div>
        <div
          class="filtering"
        >
          <div class="black">
            <b-icon-search
              v-if="searchLocation == ''"
              class="search1"
              style="position: absolute; left:5px; top: 12px"
            />
            <b-icon-x-circle
              v-else-if="searchLocation != '' && !noSearch"
              class="search1"
              style="position: absolute; right: 20px; top: 12px; cursor: pointer"
              @click="searchLocation = ''"
            />
            <input
              v-model="searchLocation"
              autocomplete="off"
              type="text"
              name="search-box"
              :placeholder="`${$t('Location')} `"
              :style="getFilterLocation.length > 0 && noSearch ? 'border-radius: 8px 8px 0px 0px' : ''"
              @keyup="inputChanged"
              @keydown.down="onArrowDown"
              @keydown.up="onArrowUp"
            >
          </div>
          <div
            v-if="getFilterLocation.length > 0 && noSearch"
            ref="scrollContainer"
            class="dropdrop"
            :style="getFilterLocation.length > 7 ? 'height: 210px' : 'height: auto'"
          >
            <div
              v-for="(result, index) in getFilterLocation"
              :key="index"
              ref="options"
              style="cursor: pointer"
              class="search-item"
              :class="{ 'is-active': index === arrowCounter }"
              @click="searchByOn(result); noSearch = false"
            >
              <p style="margin: 0">
                {{ result.locationName }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        style="width: 100%;padding-top: 16px; margin-top: 15px; height: 78vh; overflow-y: scroll;"
        class="scroll"
      >
        <table
          ref="exportable_table"
          class="team_table"
        >
          <thead style="position: sticky; top: -18px">
            <tr>
              <th>
                Store
              </th>
              <th>
                {{ $t('Name') }}
              </th>
              <th>
                Type
              </th>
              <th>
                {{ $t('Notes') }}
              </th>
              <th>
                {{ $t('Items') }}
              </th>
              <th>
                {{ $t('Actions') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in filteredLocationss"
              :key="item.locationId"
              class="hover-row"
            >
              <td>
                {{ item.storeName }}
              </td>
              <td>
                {{ item.locationName }}
              </td>
              <td>
                {{ $t(item.locationType) }}
              </td>
              <td>
                <button
                  v-b-modal.location-notes
                  class="editButton"
                  @click="notesReview(item)"
                >
                  {{ $t('Notes') }}
                </button>
              </td>
              <td>
                {{ item.counter }}
              </td>
              <td
                v-b-modal.modal-edit-location
                @click="editItem(item)"
              >
                <p
                  class="p-0 m-0 ml-50"
                >
                  <button class="editButton">
                    {{ $t('Edit') }}
                  </button>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <AddLocation @location="addLocationn" />
    <EditLocation
      :edit-supp="editLocationn"
      @edit="editLoc"
    />
    <LocationNotes :edit-supp="editLocationn" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import StoresDropdown from '@/components/StoresDropdown.vue';
import AddLocation from './modals/AddLocation.vue';
import EditLocation from './modals/EditLocation.vue';
import LocationNotes from './modals/LocationNotes.vue'

/*  eslint-env es6  */
export default {
  components: {
    StoresDropdown,
    AddLocation,
    EditLocation,
    LocationNotes,
  },
  data() {
    return {
      searchLocation: '',
      noSearch: false,
      toggleSarch: false,
      storesId: null,
      store: { storeId: null, storeName: 'No filiale', storeGLN: null },
      show: true,
      searchCN: '',
      searchON: '',
      searchByContactName: '',
      searchBySupplierName: '',
      locationtype: null,
      locationByType: null,
      page: 1,
      pageSize: 15,
      editLocationn: {
        locationName: '',
        storeName: '',
        locationType: '',
        notes: '',
      },
      deactivateItemId: {},
      totalItems: '',
      rows: [],
      arrowCounter: 0,
      enterPressed: false,
      getLocationTypes: [
        { value: 'WarehouseForClient', label: this.$t('WarehouseForClient') },
        { value: 'WarehouseForProductInStock', label: this.$t('WarehouseForProductInStock') },
        { value: 'WarehouseForMaterialVariants', label: this.$t('WarehouseForMaterialVariants') },
        { value: 'Showroom', label: this.$t('Showroom') },
        { value: 'Shop', label: this.$t('Shop') },
        { value: 'MainWarehouse', label: this.$t('MainWarehouse') },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'getLanguage',
      'getSuppliers',
      'getCurrentPageForSuppliers',
      'getTotalItemsForSuppliers',
      'getLoggedInUser',
      'getFilteredSuppliersByCN',
      'getLocation',
      // 'getTotalItemsForLocations',
      'getLocationsByType',
      'getLocationByPagination',
      'getLocationByPaginationTotalItems',
      'getFilterLocation',
      'getResetLocation',
      'getAllStoresDropdown',
    ]),
    filteredStores() {
      const filteredStores = this.getAllStoresDropdown.filter((store) => { return store.storeName !== 'No filiale' && store.storeName !== 'Unassigned' });

      const allOption = { storeId: null, storeName: 'No filiale', storeGLN: null };
      const unassigned = { storeId: '00000000-0000-0000-0000-000000000000', storeName: 'Unassigned', storeGLN: null };
      return [allOption, unassigned, ...filteredStores];
    },
    filteredLocationss() {
      const filteredLocations = this.getLocationByPagination.filter((location) => {
        const matchesSearch = location.locationName.toLowerCase().includes(this.searchLocation.toLowerCase());
        return matchesSearch;
      });

      return filteredLocations;
    },
  },
  watch: {
    searchLocation(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value === '') {
          this.removeLists();
          // this.locationPagination({
          //   // storeId: this.storeId,
          // });
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return;
        } else {
        // Set locationId to null explicitly when searching
          this.locationId = null;
          this.filterLocationBy(value);
          this.noSearch = true;
        }
      }, 500)
    },
    store(value) {
      if (value) {
        this.locationPagination({
          storeId: value.storeId,
        })
        console.log(this.filteredLocationss)
      }
    },
  },
  mounted() {
    this.loadLocationTypes()
  },
  methods: {
    ...mapActions([
      'loadSuppliers',
      'addSupplier',
      'editSupplier',
      'getSuppliersCompanyNameLikeORContactNameLike',
      'deactivateSupplierById',
      'changeLoadingtoTrue',
      'resetFilteredSupplierBySN',
      'filterSuppliersByCN',
      'resetFilteredSupplierByCN',
      'addLocation',
      'addLocationWithStore',
      'editLocation',
      'locationi',
      'loadLocationTypes',
      'locations_by_type',
      'locationPagination',
      'filterLocationBy',
      'resetLocationBy',
      'loadAllStoresByDropdown',
    ]),
    async searchByOn(value) {
      this.searchLocation = value.locationName
      this.noSearch = false
      await this.locationPagination({
        locationId: value.locationId,
        successCallback: () => {
        },
      })
    },
    addLocationn(value) {
      this.addLocationWithStore({
        object: value,
        successCallback: () => {
          this.locationPagination({
          })
        },
      });
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getLocationByPagination.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredOrdersbyON = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getFilterLocation[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    updateFiliale(newFiliale) {
      this.store = newFiliale;
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    editItem(item) {
      this.editLocationn = item;
    },
    notesReview(item) {
      this.editLocationn = item.notes;
    },
    async editLoc(obj) {
      await this.editLocation({
        object: obj,
        successCallback: () => {
          this.locationPagination({
          })
        },
      });
    },
    deactivate(obj) {
      this.deactivateItemId = obj;
    },
    removeLists() {
      this.noSearch = false;
      this.resetLocationBy();
    },
  },
};
</script>

<style scoped lang="scss">
.table_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 5px;
}

.team_table {
  width: 100%;
  font-size: 12px;
}
.team_table td {
  padding: 11px 9px !important;
}

.team_table tr {
  border-bottom: 1px solid #dcdfe6;
  color: #475467;
}
.hover-row:hover {
    background-color: rgba(255, 39, 79, 0.1);
}
.active2 {
  font-weight: bold;
  color: $base-color;
}

@media screen and (max-width:1700px) {
.tabela{
  width: 28% !important;
}

}
@media screen and (max-width: 1200px) {
  .tabela{
  width: 30% !important;
}
  .scroll {
    overflow-x: scroll;
  }

}

.dropdrop::-webkit-scrollbar {
display: none;

}
.dropdrop {
display: inline-block;
overflow: auto;
position: absolute;
background: white;
width: 227px;
box-shadow: none;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}
.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}
.search-item:last-child {
  border-bottom: none;
}
.search-item:hover {
  background: #82868c;
  color: white;
}
.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}
.is-active {
  background-color: #dedede;
}
</style>
